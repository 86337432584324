import {
	USER_PREFERENCE_DEFAULTS,
	userPreferencesState,
	type UserPreferenceKeys,
} from '$lib/utilities/userPreferences/consts';
import { updateUserPreferenceDirectly } from '$lib/utilities/userPreferences/updateUserPreference';
import { Preferences } from '@capacitor/preferences';
import { keys } from 'lodash-es';

export async function initialiseUserPreferences() {
	userPreferencesState.set('initialising');
	const existingUserKeys = await Preferences.keys();
	const userPreferenceDefaultKeys = keys(USER_PREFERENCE_DEFAULTS) as UserPreferenceKeys[];

	// Check if any of the default keys are missing from the user preferences.
	const missingUserPreferenceKeys = userPreferenceDefaultKeys.filter(
		(key) => !existingUserKeys.keys.includes(key),
	);

	// Set the missing keys to their default values.
	const setNewDefaults = missingUserPreferenceKeys.map(async (key) =>
		updateUserPreferenceDirectly(key, USER_PREFERENCE_DEFAULTS[key as UserPreferenceKeys]),
	);

	await Promise.all(setNewDefaults);

	// Check if there's any keys in the user preferences that are not in the default preferences. (i.e. old keys)
	const oldUserPreferenceKeys = existingUserKeys.keys.filter(
		(key): key is UserPreferenceKeys =>
			!userPreferenceDefaultKeys.includes(key as UserPreferenceKeys),
	);

	const removals = oldUserPreferenceKeys.map(async (key) => Preferences.remove({ key }));
	await Promise.all(removals);
	userPreferencesState.set('ready');
}
